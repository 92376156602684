import React, { Component } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import ReportCenterItemInline from './report_center_item_inline'
import ReportCenterItemPublished from './report_center_item_published'

import { onConfirm } from 'Shared/helpers'

export default class ReportCenterItemBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      edit_name: false,
      job: { id: null, error: false, completed_links: null, show_spiner: false, status_updates: []},
      show_preview: false,
    }

    this.toggleItemName = this.toggleItemName.bind(this)
    this.saveItemName = this.saveItemName.bind(this)
    this.printReport = this.printReport.bind(this)
    this.pollJob = this.pollJob.bind(this)
    this.updateRciThumbnailUrl = this.updateRciThumbnailUrl.bind(this)
    this.downloadFileFromUrl = this.downloadFileFromUrl.bind(this)
    this.setJobId = this.setJobId.bind(this)
    this.generateDocx = this.generateDocx.bind(this)
    this.redirectUnlessJobsRunning = this.redirectUnlessJobsRunning.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.togglePreview = this.togglePreview.bind(this)
  }

  toggleItemName() {
    this.setState(update(this.state, {
      edit_name: { $set: !this.state.edit_name },
    }))
  }

  togglePreview() {
    this.setState(update(this.state, {
      show_preview: { $set: !this.state.show_preview },
    }))
  }

  deleteItem() {
    onConfirm(!window.I18n ? '' : window.I18n.t('scorecards_center.report_center_item_base.delete_confirm'), () => {
      $.ajax('/api/report_center/report_center_items/' + this.props.report_center_item.id, {
        method: 'DELETE',
        data: { league_id: this.props.league_id },
        success: () => {
          this.props.removeAllItemInstances(this.props.report_center_item.id)
        },
      })
    })
  }

  saveItemName(value) {
    $.ajax('/api/report_center/report_center_items/' + this.props.report_center_item.id, {
      method: 'PATCH',
      data: { report_center_item: { name: value }, league_id: this.props.league_id },
      success: () => {
        this.props.updateItemStateAttribute(this.props.report_center_item.id, 'name', value)
        this.toggleItemName()
      },
    })
  }

  //print report by calling rci's url or throught the provided parameters
  printReport(_url = null, _type = 'GET', _data = {}, _callback = () => {}) {
    let url = _url || this.props.report_center_item.action[1]

    url = url.indexOf('?') < 0 ? url + '?report_center_item_id=' + String(this.props.report_center_item.id) : url + '&report_center_item_id=' + String(this.props.report_center_item.id)
    $.ajax(url, {
      method: _type,
      dataType: 'json',
      data: _data,
      error: () => {
        this.setJobId({id: null, error: true})
      },
      success: (data) => {
        _callback()
        if (data.job_id){ //no conflicts
          this.setJobId({ id: data.job_id, error: false, show_spiner: true })
        } else if (data.edit_conflicts_path) { //conflicts or another form rul response
          //tell modal to load form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
          this.props.openPrintModal({ edit_conflicts_path: data.edit_conflicts_path, rci_base_printReport: this.printReport, rci_base_setJobId: this.setJobId})
        } else if (data.window_redirect){
          //when printing a spreadsheet report a redirect to the edit reprot might be received as a response
          window.location.href = data.window_redirect
        }
      },
    })
  }

  generateDocx() {
    this.setJobId({ show_spiner: true })
    $.ajax('/docx/convert', {
      method: 'POST',
      dataType: 'json',
      data: {
        url: this.state.job.completed_links.pdf_path,
        name: this.props.report_center_item.name,
        primary_docx_service: this.state.job.completed_links.primary_docx_service,
        league_id: this.props.league_id,
      },
      error: () => {
        this.setJobId({id: null, error: true})
      },
      success: (data) => {
        if (data.job_id){ //no conflicts
          this.setJobId({ id: data.job_id, error: false })
        } else if (data.edit_conflicts_path) { //conflicts
          this.props.openPrintModal({ edit_conflicts_path: data.edit_conflicts_path, rci_base_setJobId: this.setJobId})
        }
      },
    })
  }

  pollJob (jobId) {
    //regular timed requests
    setTimeout(() => {
      $.ajax('/jobs/' + jobId + '/poll', {
        method: 'GET',
        dataType: 'json',
        success: (messages) => {
          const lastMessage = messages[messages.length - 1]
          //update status_updates tooltip
          if (lastMessage && lastMessage['type'] === 'message') {
            if (this.state.job.status_updates !== lastMessage['value']) {
              this.setJobIdNoPolling({
                status_updates: this.state.job.status_updates.concat(lastMessage['value']),
              }, () => {})

              setTimeout(() => {
                window.redo_qtip()
              }, 250)
            }
          }

          //done
          if (lastMessage && lastMessage['type'] === 'job-update' && lastMessage['value']['status'] === 'completed') {
            if (lastMessage['value']['pdf_path'] || lastMessage['value']['docx_path']){
              const completedLinks = {
                pdf_path: lastMessage['value']['pdf_path'] || (this.state.job.completed_links ? this.state.job.completed_links.pdf_path : null),
                button_label: lastMessage['value']['button_label'] || (this.state.job.completed_links ? this.state.job.completed_links.button_label : 'Download'),
                docx_path: lastMessage['value']['docx_path'],
                primary_docx_service: lastMessage['value']['primary_docx_service'],
              }
              this.setJobId({
                id: null,
                error: false,
                completed_links: completedLinks,
                status_updates: [],
              })
              if (lastMessage['value']['print_count']) {
                this.props.updateItemStateAttribute(this.props.report_center_item.id, 'print_count', lastMessage['value']['print_count'])
              }
              //this.downloadFileFromUrl(lastMessage["value"]["pdf_path"] || lastMessage["value"]["docx_path"]);
            } else {
              this.setJobId({id: null, error: true, completed_links: null})
            }
          } else if (lastMessage && lastMessage['value']['status'] === 'errors') {
            //errors
            this.setJobId({id: null, error: true, completed_links: null })
            return
          } else {
            //in progress - keep polling
            //TO DO: handle status updates
            this.pollJob(jobId)
          }
        },
      })
    }, 2000) //polling interval 3s
  }

  updateRciThumbnailUrl(thumbnailUrl) {
    this.setState(update(this.state, { report_center_item: { image_url: { $set: thumbnailUrl }}}))
  }

  //TO DO create a single dummy anchor for all download calls
  downloadFileFromUrl(url, fileName = '') {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  setJobIdNoPolling(jobHash, _callback) {
    jobHash['show_spiner'] = jobHash.show_spiner || false
    jobHash = $.extend(this.state.job, jobHash) //just update jobHash keys
    this.setState({ job: jobHash }, () => {}, _callback)
  }

  //TO DO: make it clear this will also start the polling
  setJobId(jobHash, _callback) {
    jobHash['show_spiner'] = jobHash.show_spiner || false
    jobHash = $.extend(this.state.job, jobHash) //just update jobHash keys
    this.setState({ job: jobHash }, () => {
      if (jobHash.id !== null) {
        this.pollJob(jobHash.id)
      }
    }, _callback)
  }

  //show confirm window if jobs running
  //hack - getting app state from native dom
  redirectUnlessJobsRunning(url) {
    if ($('tr.in_progress, div.in_progress').length === 0) {
      window.location.href = url
    } else {
      onConfirm(!window.I18n ? '' : window.I18n.t('scorecards_center.report_center_item_base.leave_page'), () => {
        window.location.href = url
      })
    }
  }

  render() {
    const _functions = {
      toggleItemName: this.toggleItemName,
      updateItemName: this.updateItemName,
      saveItemName: this.saveItemName,
      printReport: this.printReport,
      pollJob: this.pollJob,
      updateRciThumbnailUrl: this.updateRciThumbnailUrl,
      downloadFileFromUrl: this.downloadFileFromUrl,
      setJobId: this.setJobId,
      updateListCount: this.updateListCount,
      generateDocx: this.generateDocx,
      togglePrintOptionsModal: this.togglePrintOptionsModal,
      redirectUnlessJobsRunning: this.redirectUnlessJobsRunning,
      deleteItem: this.deleteItem,
      togglePreview: this.togglePreview,
    }

    return this.props.mode === 'download_center' ?
        <ReportCenterItemInline {...{ ...this.props, ...this.state, ..._functions}} />
      :
        <ReportCenterItemPublished {...{ ...this.props, ...this.state, ..._functions}} />
  }
}

ReportCenterItemBase.propTypes = {
  report_center_item: PropTypes.object,
  league_id: PropTypes.string,
  mode: PropTypes.string,
  openPrintModal: PropTypes.func,
  updateItemStateAttribute: PropTypes.func,
  removeAllItemInstances: PropTypes.func,
  isTeamCaptain: PropTypes.bool.isRequired,
  canUseLegacyScorecards: PropTypes.bool.isRequired,
}
