import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReportCenterItemBase from './report_center_item_base'
import flat from 'array.prototype.flat'
import ReactDOMServer from 'react-dom/server'

class ReportCenterList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
    this.scrollList = this.scrollListFunc.bind(this)
    this.scrollEvent = this.listenScrollEvent.bind(this)
  }

  sortPosition(i1, i2) {
    return (i1.print_count <= i2.print_count) ? 1 : -1
  }

  getExpandedStatus() {
    return this.state.expanded || (this.props.search_term !== '' && this.props.report_center_list.display)
  }

  componentDidMount() {
    this.initializeCustomDropdowns()
  }

  componentDidUpdate() {
    this.initializeCustomDropdowns()
  }

  initializeCustomDropdowns() {
    $('.hover-dropdown-button').off('click')
    $('.hover-dropdown-button').on('click', function (e) {
      e.stopPropagation()
      const ul = $(this).next('ul')
      const wasOpen = ul.attr('data-is-open')
      $('.hover-dropdown').hide()
      $('.hover-dropdown').attr('data-is-open', 'closed')
      if (wasOpen === 'opened') {
        ul.attr('data-is-open', 'closed')
      } else {
        ul.show()
        ul.attr('data-is-open', 'opened')
      }
    })
  }

  //custom items for the virtual lists
  //pull non published items from this.props.report_center_lists,
  //to maintain all item functionality from old report center
  getItems() {
    let list = []

    //lookup last printed in all lists by list rci_last_printed_id
    if (this.props.index === 0){
      const lastPrintedScorecard = flat(this.props.report_center_lists.map((l) => { return l.report_center_items }))
                                  .find((rci) => { return rci.id === this.props.report_center_list.rci_last_printed_id })
      if (lastPrintedScorecard){
        list = [ lastPrintedScorecard ]
      }
    } else if (this.props.index === 2) { //either display fake category's rcis or all @reporter_center_lists' rcis in list 2
      list = flat(this.props.report_center_lists.map((l) => { return l.report_center_items }))
      
      //make list uniq & filter our legacy
      const uniqRciIds = {}
      const uniqList = []
      list.forEach( rci => {
        if (!uniqRciIds[rci.id] && rci.report_type !== 'ScorecardSetting') {
          uniqList.push(rci)
          uniqRciIds[rci.id] = true
        }
      })

      return uniqList
    } else if (this.props.index === 1) { //pull legacy from @report_center_list
      const allItems = flat(this.props.report_center_lists.map((l) => { return l.report_center_items }))
      const legacyRCI = allItems.find( (i) => { return i.report_type === 'ScorecardSetting' })
      if (legacyRCI){
        legacyRCI.edit_path[0] = !window.I18n ? '' : window.I18n.t('buttons.edit') //change button text
        list = [ legacyRCI ]
      }
    } else {
      list = this.props.report_center_list.report_center_items
    }

    return list
  }

  scrollListFunc(e, direction) {
    const amount = 600
    const list = $(e.target.closest('.report-list-container'))
    let pos = list.scrollLeft()
    if (direction === 'left') {
      pos -= amount
    } else {
      pos += amount
    }
    list.animate({scrollLeft: pos}, 250)
  }
  
  getCssClass(index, isTmLite, canUseLegacyScorecards) {
    let cssClass = ''
    if (isTmLite) {
      cssClass = index === 1 ? 'small-list col-xs-12' : 'no-lite'
    } else if (canUseLegacyScorecards) {
      cssClass = [ 0, 1 ].includes(index) ? 'small-list col-xs-12 col-sm-6' : 'col-xs-12'
    } else {
      cssClass = 'col-xs-12'
    }
    return cssClass
  }

  listenScrollEvent(e) {
    const element = $(e.target)
    const scrollPercentage = 100 * element.scrollLeft() / (e.target.scrollWidth - e.target.clientWidth)
    const leftArrow = element.find('.fa-chevron-circle-left').first()
    const rightArrow = element.find('.fa-chevron-circle-right').first()
    scrollPercentage === 0 ? leftArrow.addClass('sa-icon-disabled') : leftArrow.removeClass('sa-icon-disabled')
    scrollPercentage === 100 ? rightArrow.addClass('sa-icon-disabled') : rightArrow.removeClass('sa-icon-disabled')
  }
  
  render() {
    return (
      !(this.getItems().length === 0 && this.props.index === 2) && <div className='expanded' data-id={this.props.data_id}>
        <div className={this.getCssClass(this.props.index, this.props.isTmLite, this.props.canUseLegacyScorecards) }>
          <div className="report-list ps-container">
            <div className="row report-list-header">
              <a className="list-header-name" onClick={() => { this.setState({ expanded: !this.getExpandedStatus() }) }}>
                <span className="header_small_text">{ this.props.report_center_list.short_name }</span>
                <span className="header_large_text">{ this.props.report_center_list.name }</span>
              </a>
              { !this.props.isTeamCaptain && 
                <span>
                  {
                    !(this.props.index === 4) && <a href={ `${this.props.report_center_list.button.url}?category=scorecards` }>
                      <div className='btsp_bullet_button btn_fix'>
                        <div className='icon'><i className={ this.props.report_center_list.button.icon }></i></div>
                        <div className='btsp_align-middle header_small_text'
                            data-toggle='modal'
                            data-target='.create_item_wrapper'>{ this.props.report_center_list.button.short_text }</div>
                        <div className='btsp_align-middle header_large_text'
                            data-toggle='modal'
                            data-target='.create_item_wrapper'>{ this.props.report_center_list.button.text }</div>
                      </div>
                    </a>
                  }
                </span>
              }
            
            </div>
            {
              <div className={ `report-list-container ${this.props.index !== 1 ? 'inline' : ''}` } onScroll={ (e) => { this.scrollEvent(e) } } >
                {( this.getItems().length !== 0 && [ 2, 3 ].includes(this.props.index) ?
                <span className="scroll-arrow sa-left" onClick={ (e) => { this.scrollList(e, 'left') } }>
                  <i className="fa fa-chevron-circle-left sa-icon sa-icon-disabled"></i>
                </span> : null
                )}
                { this.props.index === 1 &&
                  <div className='phase_out_warning btsp_alert_notices'>
                    <span dangerouslySetInnerHTML={{
                      __html: window.I18n.t(
                        'scorecards_center.phase_out_warning',
                        { link: ReactDOMServer.renderToString(<a href={'https://docs.golfgenius.com/article/show/11477-printing-scorecards?adminView=true'} className='blue' target='_blank'>{window.I18n.t('scorecards_center.here')}</a>) }),
                    }} />
                  </div>
                }
                {( this.getItems().length === 0 && this.props.index === 0 ?
                  <div className={this.props.canUseLegacyScorecards ? 'add-margin-top-45 add-margin-bottom-90' : ''}>
                    <img src={require('../../../assets/images/create_scorecards_placeholder.png')} className='img_placeholder' />
                  </div> :
                  this.getItems().sort(this.sortPosition)
                      .map((reportCenterItem, _index) => (
                    <ReportCenterItemBase key={`${this.props.report_center_list.id}_${reportCenterItem.id}`}
                      report_center_lists={this.props.report_center_lists.filter((l) => { return [ 'Basic Reports', 'Basic Spreadsheets', 'Page Composer', 'Report Composer', 'Spreadsheet Composer' ].indexOf(l.name) === -1 } ) }
                      report_center_item={reportCenterItem}
                      current_report_center_list={this.props.report_center_list}
                      addRemoveItemToList={this.props.addRemoveItemToList}
                      importDiscoverCenter={this.props.importDiscoverCenter}
                      cloneItem={this.props.cloneItem}
                      openPrintModal={this.props.openPrintModal}
                      index={_index}
                      league_id={this.props.league_id}
                      layout={this.props.layout}
                      updateItemStateAttribute={this.props.updateItemStateAttribute}
                      removeAllItemInstances={this.props.removeAllItemInstances}
                      mode={ this.props.index !== 3 ? 'download_center' : 'discover_center' }
                      listIndex={ this.props.index }
                      isTeamCaptain={this.props.isTeamCaptain}
                      canUseLegacyScorecards={this.props.canUseLegacyScorecards} />
                  ))
                )}
                {( this.getItems().length !== 0 && [ 2, 3 ].includes(this.props.index) ?
                <span className="scroll-arrow sa-right" onClick={ (e) => { this.scrollList(e, 'right') } }>
                  <i className="fa fa-chevron-circle-right sa-icon"></i>
                </span> : null
                )}
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

ReportCenterList.propTypes = {
  league_id: PropTypes.string.isRequired,
  data_id: PropTypes.string.isRequired,
  report_center_lists: PropTypes.array.isRequired,
  report_center_list: PropTypes.object.isRequired,
  popup_enabled: PropTypes.bool.isRequired,
  layout: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  search_term: PropTypes.string.isRequired,
  updateItemStateAttribute: PropTypes.func.isRequired,
  addRemoveItemToList: PropTypes.func.isRequired,
  removeAllItemInstances: PropTypes.func.isRequired,
  createList: PropTypes.func.isRequired,
  editList: PropTypes.func.isRequired,
  removeList: PropTypes.func.isRequired,
  importDiscoverCenter: PropTypes.func.isRequired,
  openPrintModal: PropTypes.func.isRequired,
  showPhaseOutPopup: PropTypes.func.isRequired,
  cloneItem: PropTypes.func.isRequired,
  isTeamCaptain: PropTypes.bool.isRequired,
  isTmLite: PropTypes.bool.isRequired,
  canUseLegacyScorecards: PropTypes.bool.isRequired,
}

export default ReportCenterList
