import React, { Component } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import ReportCenterList from './report_center_list'
import PrintModalContainer from './print_modal_container'

class ScorecardsCenter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      report_center_lists: props.report_center_lists,
      search_term: '',
      league_id: props.league_id,
      round_id: props.round_id,
      layout: 'inline',
      modal_parameters: null,
    }

    this.toggleFavourite = this.toggleFavourite.bind(this)
    this.addRemoveItemToList = this.addRemoveItemToList.bind(this)
    this.importDiscoverCenter = this.importDiscoverCenter.bind(this)
    this.cloneItem = this.cloneItem.bind(this)
    this.removeList = this.removeList.bind(this)
    this.createList = this.createList.bind(this)
    this.editList = this.editList.bind(this)
    this.importItem = this.importItem.bind(this)
    this.updateItemStateAttribute = this.updateItemStateAttribute.bind(this)
    this.openPrintModal = this.openPrintModal.bind(this)
    this.removeAllItemInstances = this.removeAllItemInstances.bind(this)
    this.setFilterTerm = this.setFilterTerm.bind(this)
  }

  componentDidMount() {
    //initializeCustomDropdowns
    $(document).on('click', (e) => {
      const ul = e.target.closest('ul')
      if (!ul || ul.className !== 'hover-dropdown') {
        $('.hover-dropdown').hide()
        $('.hover-dropdown').attr('data-is-open', 'closed')
      }
    })
    //$('.report-list-container').perfectScrollbar()
    window.redo_qtip()
  }

  toggleFavourite(listId, value) {
    $.ajax('/api/report_center/report_center_lists/' + listId, {
      method: 'PATCH',
      data: { report_center_list: { leagues_report_center_list: { league_id: this.state.league_id, favourite: value }}, league_id: this.state.league_id},
      success: () => {
        const rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === listId })

        this.setState(update(this.state, {
          report_center_lists: {
            [rclIndex]: {
              favourite: { $set: value },
            },
          },
        }))
      },
    })
  }

  updateItemStateAttribute(rciId, key, value) {
    const map = { report_center_lists: {} }
    this.state.report_center_lists.forEach((list, i) => {
      const j = list.report_center_items.findIndex(rci => (rci.id === rciId))
      if (j !== -1) {
        map.report_center_lists[i] = { report_center_items: { [j]: { [key]: { $set: value} }}}
      }
    })
    this.setState(update(this.state, map))
  }

  //clones and adds to listId api call
  importItem(rciId, listId) {
    const rclIndex = this.state.report_center_lists.findIndex(rcl => (rcl.id === listId))

    $.ajax('/api/report_center/report_center_items/' + rciId + '/import', {
      method: 'PATCH',
      data: { report_center_list_id: listId, league_id: this.props.league_id, round_id: this.props.round_id },
      success: (data) => {
        const newRci = data['rci']
        newRci['display'] = true

        this.setState(
          update(this.state, {
            report_center_lists: {
              [rclIndex]: {
                report_center_items: { $push: [ newRci ] },
              },
            },
          }),
          () => {
            this.updateItemStateAttribute(rciId, 'list_count', data['list_count'])
          }
        )
      },
      error: () => { console.error('unable to clone report center item to list') },
    })
  }
  
  importDiscoverCenter(rciId, listId, op) {
    const newRclIndex = this.state.report_center_lists.findIndex(rcl => (rcl.id === listId))

    if (op.target.checked) {
      this.importItem(rciId, listId)
    } else {
      const newRcl = this.state.report_center_lists.filter(rcl => (rcl.id === listId))[0]
      const rciIndex = newRcl.report_center_items.findIndex(rci => (rci.parent_id === rciId))
      const rci = newRcl.report_center_items[rciIndex]

      $.ajax('/api/report_center/report_center_items_lists/0', {
        method: 'DELETE',
        data: { report_center_items_lists: { report_center_list_id: listId, report_center_item_id: rci.id }, rci_print_id: rciId, league_id: this.state.league_id},
        success: (data) => {
          this.setState(
            update(this.state, {
              report_center_lists: {
                [newRclIndex]: {
                  report_center_items: { $splice: [ [ rciIndex, 1 ] ] },
                },
              },
            }),
            () => {
              this.updateItemStateAttribute(rciId, 'list_count', data['list_count'])
            },
          )
        },
        error: () => { console.error('unable to add/remove report center item to list') },
      })
    }
  }

  addRemoveItemToList(rci, rcl, op) {
    const newRclIndex = this.state.report_center_lists.findIndex((_rcl) => { return _rcl.id === rcl.id })
    if (op.target.checked) {
      $.ajax('/api/report_center/report_center_items_lists', {
        method: 'POST',
        data: { report_center_items_lists: { report_center_list_id: rcl.id, report_center_item_id: rci.id}, rci_print_id: rci.id, league_id: this.state.league_id },
        success: (data) => {
          this.setState(
            update(
              this.state,
              {
                report_center_lists: {
                  [newRclIndex]: {
                    report_center_items: { $push: [ rci ] },
                  },
                },
              }),
            () => { this.updateItemStateAttribute(rci.id, 'list_count', data['list_count']) }
          )
        },
        error: () => { console.error('unable to add report center item to list') },
      })
    } else {
      const rciIndex = rcl.report_center_items.findIndex((_rci) => { return _rci.id === rci.id })

      $.ajax('/api/report_center/report_center_items_lists/0', {
        method: 'DELETE',
        data: { report_center_items_lists: { report_center_list_id: rcl.id, report_center_item_id: rci.id }, rci_print_id: rci.id, league_id: this.state.league_id, customer_id: this.props.customer_id},
        success: (data) => {
          if (data.success){
            this.setState(
              update(
                this.state,
                {
                  report_center_lists: {
                    [newRclIndex]: {
                      report_center_items: { $splice: [ [ rciIndex, 1 ] ] },
                    },
                  },
                }
              ),
              () => { this.updateItemStateAttribute(rci.id, 'list_count', data['list_count']) }
            )
          }
        },
        error: () => { console.error('unable to add report center item to list') },
      })
    }
  }

  sortPosition(a, b) {
    return a.position - b.position
  }

  setFilterTerm(event) {
    this.filterResults(event.target.value)
  }

  filterResults(term) {
    term = term.toLowerCase()
    const displayHash = { search_term: { $set: term }, report_center_lists: {}}
    this.state.report_center_lists.forEach((list, i) => {
      displayHash.report_center_lists[i] = {
        display: { $set: (term === '') },
        report_center_items: {},
      }

      list.report_center_items.forEach((item, j) => {
        if (item.name.toLowerCase().indexOf(term) !== -1){
          displayHash.report_center_lists[i].report_center_items[j] = { display: { $set: true }}
          displayHash.report_center_lists[i].display = { $set: true }
        } else {
          displayHash.report_center_lists[i].report_center_items[j] = { display: { $set: false }}
        }
      })
    })

    this.setState(update(this.state, displayHash))
  }

  cloneItem(rci, listId) {
    const rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === listId })
    const rcis = this.state.report_center_lists[rclIndex].report_center_items
    const rciIndex = rcis.findIndex((rciCurrent) => { return rciCurrent === rci }) + 1

    $.ajax('/api/report_center/report_center_items/' + rci.id + '/clone', {
      method: 'PATCH',
      data: { report_center_list_id: listId, league_id: this.state.league_id, round_id: this.state.round_id },
      success: (data) => {
        const newRci = data['rci']
        newRci['print_count'] = rci['print_count']
        newRci['display'] = true
        this.setState(update(this.state, {
          report_center_lists: {
            [rclIndex]: {
              report_center_items: { $set: [ ...rcis.slice(0, rciIndex), newRci, ...rcis.slice(rciIndex) ] },
            },
          },
        }))
      },
      error: () => { console.error('unable to clone report center item to list') },
    })
  }

  removeAllItemInstances(rciId) {
    const spliceHash = { report_center_lists: {}}
    this.state.report_center_lists.forEach((list, i) => {
      list.report_center_items.forEach((item, j) => {
        if (item.id === rciId) {
          spliceHash.report_center_lists[i] = { report_center_items: { $splice: [ [ j, 1 ] ] } }
        }
      })
    })

    this.setState(update(this.state, spliceHash))
  }

  removeList(rclId) {
    const rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === rclId })
    $.ajax('/api/report_center/report_center_lists/' + rclId, {
      method: 'DELETE',
      data: { league_id: this.state.league_id },
      success: () => {
        this.setState(update(this.state, {
          report_center_lists: { $splice: [ [ rclIndex, 1 ] ] },
        }))
        $('#create_list').modal('hide')
      },
      error: () => { console.error('unable to remove new report center list') },
    })
  }

  createList(name, ref) {
    $.ajax('/api/report_center/report_center_lists', {
      method: 'POST',
      data: { name: name, league_id: this.state.league_id, round_id: this.state.round_id },
      success: (data) => {
        const newRcl = data['report_center_list']
        this.setState(update(this.state, {
          report_center_lists: { $push: [ newRcl ] },
        }), () => { this.filterResults(this.state.search_term) } )
        $(ref).modal('hide')
      },
      error: () => { console.error('unable to create new report center list to list') },
    })
  }

  editList(rclId, newName, ref) {
    const rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === rclId })

    $.ajax('/api/report_center/report_center_lists/' + rclId, {
      method: 'PATCH',
      data: { report_center_list: { name: newName }, league_id: this.state.league_id },
      success: () => {
        this.setState(update(this.state, {
          report_center_lists: { [rclIndex]: { name: { $set: newName } }},
        }))
        $(ref).modal('hide')
      },
      error: () => { console.error('unable to create edit report center list name') },
    })
  }

  openPrintModal(opts) {
    this.setState({ modal_parameters: opts })
  }

  updateListPositions(listOrder, listId, newListPosition) {
    const data = { report_center_lists: { list_order: listOrder, league_id: this.state.league_id }}
    $.ajax( '/api/report_center/leagues_report_center_lists/set_positions', {
      method: 'PATCH',
      data: data,
      success: () => {
        const rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === listId })

        this.setState(update(this.state, {
          report_center_lists: {
            [rclIndex]: {
              position: { $set: newListPosition },
            },
          },
        }))
      },
    })
  }

  getLists(rcls) {
    return rcls.reduce((rv, x) => {
      (rv[x['favourite']] = rv[x['favourite']] || []).push(x)
      return rv
    }, {})
  }

  render() {
    return (
      <div className='row'>
        <div className='download-center-outter-body scorecards_center'>
          <div className='row' style={ { height: '100%'} }>
            <div className='download-center-body-wrapper col-sm-12'>
              {
                this.props.category_lists.map((reportCenterList, index) => {
                  if (!(this.props.canUseLegacyScorecards === false && index === 1)) {
                    return (
                      <ReportCenterList key={reportCenterList.id}
                                        data_id={reportCenterList.id}
                                        report_center_list={reportCenterList}
                                        report_center_lists={this.state.report_center_lists}
                                        layout={this.state.layout}
                                        popup_enabled={this.state.popup_enabled}
                                        toggleFavourite={this.toggleFavourite}
                                        search_term={this.state.search_term}
                                        addRemoveItemToList={this.addRemoveItemToList}
                                        importDiscoverCenter={this.importDiscoverCenter}
                                        cloneItem={this.cloneItem}
                                        removeList={this.removeList}
                                        createList={this.createList}
                                        editList={this.editList}
                                        index={index}
                                        league_id={this.props.league_id}
                                        updateItemStateAttribute={ this.updateItemStateAttribute }
                                        openPrintModal={this.openPrintModal}
                                        showPhaseOutPopup={this.showPhaseOutPopup}
                                        removeAllItemInstances={this.removeAllItemInstances}
                                        isTeamCaptain={this.props.is_team_captain} 
                                        isTmLite={this.props.is_tm_lite}
                                        canUseLegacyScorecards={this.props.canUseLegacyScorecards}/>
                    )
                  }
                })
              }
            </div>
          </div>
        </div>
        <PrintModalContainer modal_parameters={ this.state.modal_parameters }
                             openPrintModal = {this.openPrintModal } />
        <img style={ { height: '0px' } } src={ require('report_center/no-image.png') } />
      </div>
    )
  }
}

ScorecardsCenter.propTypes = {
  customer_id: PropTypes.number.isRequired,
  category_lists: PropTypes.array.isRequired,
  report_center_lists: PropTypes.array.isRequired,
  league_id: PropTypes.string.isRequired,
  round_id: PropTypes.string,
  enable_discover: PropTypes.bool.isRequired,
  is_team_captain: PropTypes.bool.isRequired,
  is_tm_lite: PropTypes.bool.isRequired,
  canUseLegacyScorecards: PropTypes.bool.isRequired,
}

export default ScorecardsCenter
